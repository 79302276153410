export default {
  seo: {
    title: 'Финансовая отчетность за 2023 год | Банк 131',
    description: 'Финансовая отчетность за 2023 год'
  },
  title: 'Финансовая отчетность за 2023 год',
  content: [
    {
      label: 'Дата размещения 19.03.2024',
      title: 'Годовая бухгалтерская (финансовая) отчетность за 2023 год',
      link: '/legal/ru/godovaya_buhgalterskaya_finansovaya_otchetnost_za_2023_god.pdf'
    },
    {
      label: 'Дата размещения 19.03.2024',
      title: 'Дополнительная информация к годовой бухгалтерской (финансовой) отчетности за 2023 год',
      link: '/legal/ru/dopolnitelnaya-informaciya-k-godovoj-buhgalterskoj-finansovoj-otchetnosti-za-2023-god.pdf'
    },
    {
      label: 'Дата размещения 19.03.2024',
      title: 'Аудиторское заключение о годовой бухгалтерской финансовой отчетности за 2023 год',
      link: '/legal/ru/auditorskoe_zaklyuchenie_o_godovoj_buhgalterskoj_finansovoj_otchetnosti_za_2023_god.pdf',
      clarification: {
        label: '',
        text: 'Годовая бухгалтерская (финансовая) отчетность за 2023 год и дополнительная информация к нему, сформированная с учетом принятых Советом директоров Банка России 26.12.2023 решением «О требованиях к раскрытию кредитными организациями (головными кредитными организациями банковских групп) отчетности и информации в 2024 году».'
      }
    },
    {
      label: 'Дата размещения 08.11.2023',
      title: 'Промежуточная бухгалтерская (финансовая) отчетность за девять месяцев 2023 года',
      link: '/legal/ru/promezhutochnaya-buhgalterskaya-finansovaya-otchetnost-za-devyat-mesyacev-2023-goda.pdf',
    },
    {
      label: 'Дата размещения 08.11.2023',
      title: 'Дополнительная информация к промежуточной бухгалтерской (финансовой) отчетности за девять месяцев 2023 года.',
      link: '/legal/ru/dopolnitelnaya-informaciya-k-promezhutochnoy-buhgalterskoy-finansovoy-otchetnosti-za-devyat-mesyacev-2023-goda.pdf',
      clarification: {
        label: '',
        text: 'Промежуточная бухгалтерская (финансовая) отчетность за девять месяцев 2023 года и дополнительная информация к нему, сформированная с учетом принятых Советом директоров Банка России 29 декабря 2022 года решением «О требованиях к раскрытию кредитными организациями (головными кредитными организациями банковских групп) отчетности и информации в 2023 году».'
      }
    },
    {
      label: 'Дата размещения 07.08.2023',
      title: 'Промежуточная бухгалтерская (финансовая) отчетность за первое полугодие 2023 года',
      link: '/legal/ru/promezhutochnaya-buhgalterskaya-finansovaya-otchetnost-za-pervoe-polugodie-2023-goda.pdf',
    },
    {
      label: 'Дата размещения 07.08.2023',
      title: 'Дополнительная информация к промежуточной бухгалтерской (финансовой) отчетности за первое полугодие 2023 года',
      link: '/legal/ru/dopolnitelnaya-informaciya-k-obobshchennoj-promezhutochnoj-buhgalterskoj-finansovoj-otchetnosti-za-2-kvartal-2023-goda.pdf',
      clarification: {
        label: '',
        text: 'Промежуточная бухгалтерская (финансовая) отчетность за первое полугодие 2023 года и дополнительная информация к нему, сформированная с учетом принятых Советом директоров Банка России 29 декабря 2022 года решением «О требованиях к раскрытию кредитными организациями (головными кредитными организациями банковских групп) отчетности и информации в 2023 году».'
      }
    },
    {
      label: 'Дата размещения 23.05.2023',
      title: 'Промежуточная бухгалтерская финансовая отчетность за 1 квартал 2023 года',
      link: '/legal/ru/promezhutochnaya-buhgalterskaya-finansovaya-otchetnost-za-1-kvartal-2023-goda_2023_05_23_3.pdf',
    },
    {
      label: 'Дата размещения 16.05.2023',
      title: 'Дополнительная информация к обобщенной промежуточной бухгалтерской финансовой отчетности за 1 квартал 2023 года',
      link: '/legal/ru/dopolnitelnaya-informaciya-k-obobshchennoj-promezhutochnoj-buhgalterskoj-finansovoj-otchetnosti-za-1-kvartal-2023-goda.pdf',
      clarification: {
        label: '',
        text: 'Промежуточная бухгалтерская (финансовая) отчетность за первый квартал 2023 года и дополнительная информация к нему, сформированная с учетом принятых Советом директоров Банка России 29 декабря 2022 года решением «О требованиях к раскрытию кредитными организациями (головными кредитными организациями банковских групп) отчетности и информации в 2023 году».'
      }
    },
    {
      title: '',
      link: '',
      clarification: {
        label: 'Дата размещения 02.05.2023',
        text: 'Дата проведения очередного (годового) общего собрания участников ' +
          '(дата принятия решения единственного участника Общества с ограниченной ответственностью "Банк 131") ' +
          ' — 27.04.2023'
      }
    }
  ]
}
